import { ProductCountAndLocationsDTO, SimilarProductsDTO, SimilarProductsInputDTO } from "models/products/products.dto";
import { IProductsService } from "./iproducts-service";

export class MockProductsService implements IProductsService {
  async listSimilarProducts(): Promise<SimilarProductsDTO[]> {
    return [
      {
          "SimilarEmbeddings.account_id": "ef926",
          "SimilarEmbeddings.site_id": "8bc1f",
          "SimilarEmbeddings.location_id": "5f3c3e67bd84e7011dc7e7b6",
          "SimilarEmbeddings.product_id": "659705350c576600011391f4",
          "SimilarEmbeddings.image_link": "https://s3-us-west-2.amazonaws.com/dutchie-images/fe799a8ac04da17f6c3f1a33f6cb17e5",
          "SimilarEmbeddings.name": "Top Smoke Ice Cream Cake Pre-Roll 28 Pack 28g",
          "SimilarEmbeddings.brand": "Top Smoke",
          "SimilarEmbeddings.category": "Pre-Rolls",
          "SimilarEmbeddings.similarity": 0.9999994533013138
      }]
  }

  async listSimilarProductsAndCounts(): Promise<ProductCountAndLocationsDTO[]>{
    return [
      {
        "products": "23",
        "locations": "16",
        "brand": 'brand',
        'category': 'category',
        'name': 'name',
        'productIndex': 0,
        'similarProducts': []
      }
      ]
    }
  }
