import React, { createContext, useState } from 'react';

export const StateContext = createContext(); // gets ability from provider

export const StateContextProvider = (props) => {
  const [nativeProducts, setNativeProducts] = useState([]);

  return <StateContext.Provider value={{ nativeProducts, setNativeProducts }}>
    {props.children}
  </StateContext.Provider>

}