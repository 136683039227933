import { ConnectedService } from "services/connected-service";
import { ConnectedServiceResult } from "services/connected-service-result";
import { IProductsService } from "./iproducts-service";
import { ProductCountAndLocationsDTO, SimilarProductsDTO, SimilarProductsInputDTO } from "models/products/products.dto";

interface SimilarProductsResult extends ConnectedServiceResult<SimilarProductsDTO[]>{}
interface ProductCountAndLocationsResult extends ConnectedServiceResult<ProductCountAndLocationsDTO[]>{}
export class ConnectedProductsService extends ConnectedService implements IProductsService {
  constructor(url: string){
    super(url);
  }

  async listSimilarProducts(input: SimilarProductsInputDTO): Promise<SimilarProductsDTO[]> {
    const result = await this._post<SimilarProductsResult>(`products/similar`, input);
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }

  async listSimilarProductsAndCounts(input: SimilarProductsInputDTO[]): Promise<ProductCountAndLocationsDTO[]> {
    const result = await this._post<ProductCountAndLocationsResult>(`products/similar-and-counts`, input);
    if(result.success === true && result.data !== undefined){
      return result.data;
    }
    throw new Error(result.message);
  }
}
