import { ConnectedService } from 'services/connected-service'
import { INativeService } from './inative-service'
import { Native, NativeDto } from 'models/dsp/native.dto'
import { ConnectedServiceResult } from 'services/connected-service-result'
import moment from 'moment'
import { creativeSizes } from 'models/dsp/banner.dto'
import _ from 'lodash'

interface NativeResult extends ConnectedServiceResult<NativeDto> {}

export class ConnectedNativeService extends ConnectedService implements INativeService {
  async create(native: Native): Promise<Native> {
    let nativeDto = this.mapNativeToDto(native)
    if (native.creativeType === 'productCards') {
      const result = await this._post<NativeResult>('dsp/natives/productNatives', nativeDto)
      if (result.success === true && result.data !== undefined) {
        return result.data
      }
      throw new Error(result.message)
    } else {
      const result = await this._post<NativeResult>('dsp/natives', nativeDto)
      if (result.success === true && result.data !== undefined) {
        return result.data
      }
      throw new Error(result.message)
    }
  }

  async update(native: Native): Promise<Native> {
    let { id, ...rest } = native
    let nativeDto = this.mapNativeToDto(rest)
    const result = await this._put<NativeResult>(`dsp/natives/${id}`, nativeDto)
    if (result.success === true && result.data !== undefined) {
      return result.data
    }
    throw new Error(result.message)
  }

  async getById(id: number): Promise<Native | null> {
    const result = await this._get<NativeResult>(`dsp/natives/${id}`)
    if (result.success === true && result.data !== undefined) {
      return this.mapDtoToNative(result.data)
    }
    throw new Error(result.message)
  }

  async getAllNatives(): Promise<Native[] | null> {
    const result = await this._get<any>(`dsp/natives`)
    if (result.success === true && result.data !== undefined) {
      return result.data?.map((d: NativeDto) => this.mapDtoToNative(d))
    }
    throw new Error(result.message)
  }

  private mapDtoToNative(dto: NativeDto): Native {
    let { intervalStart, intervalEnd, assets, ruleGroups, meta = null, contentType } = dto
    let size =
      contentType === 'image'
        ? creativeSizes.find(
            (c: any) => c.value === `${assets?.image?.width}x${assets?.image?.width}`,
          )!
        : undefined
    let {
      title = undefined,
      link = undefined,
      pixels = undefined,
      jsTracker = undefined,
      image = undefined,
      ...rest
    } = assets

    let data = _.isEmpty(rest) ? [
      {
        value: '',
        type: '',
      },
    ]: this.convertObjectToArray(rest)
    let clickTrackerUrls =
      jsTracker || pixels ? this.convertObjectToArray({ jsTracker, pixels }) : [
        {
          value: '',
          type: '',
        },
      ]
    if (image) {
      image = { ...image, imageUrlGenerateType: 'paste', size }
    }
    return {
      id: dto.id,
      attributes: dto.attributes?.map((a) => String(a)),
      bidEcpm: dto.bidEcpm,
      name: dto.name,
      dealsOptions: dto.dealsOptions,
      deals: dto.deals,
      sourceId: dto.sourceId,
      accountId: dto.accountId,
      interval: [moment(intervalStart), moment(intervalEnd)],
      ruleGroups: ruleGroups,
      categories: dto.categories,
      siteOrApp: meta?.siteOrApp,
      creativeType: 'native',
      tags: dto.tags,
      assets: { title, link, image, data, clickTrackerUrls },
    }
  }

  private convertArrayToObject(data: { type: string; value: string }[]) {
    const convertedObject = {} as any
    for (const item of data) {
      const type = item.type
      const value = item.value
      if (type === 'pixels') {
        convertedObject['pixels'] = convertedObject.hasOwnProperty('pixels')
          ? [...convertedObject['pixels'], value]
          : [value]
      } else {
        convertedObject[type] = value
      }
    }
    return convertedObject
  }

  private convertObjectToArray(data: {}) {
    const result: any[] = []
    for (const [key, value] of Object.entries(data)) {
      if (key === 'pixels') {
        let _value: string[] = value as string[]
        _value.forEach((v) => result.push({ type: key, value:v }))
      } else {
        result.push({ type: key, value: value })
      }
    }
    return result
  }

  private mapNativeToDto(native: Native): NativeDto {
    let { interval, assets, ruleGroups } = native
    const {
      data = undefined,
      clickTrackerUrls = undefined,
      contentType = undefined,
      image = undefined,
      ...rest
    } = assets

    let _assets

    if (data) _assets = { ...rest, ...this.convertArrayToObject(assets?.data!) }
    if (clickTrackerUrls) _assets = { ..._assets, ...this.convertArrayToObject(clickTrackerUrls!) }
    if (image) {
      const { width, height, imageUrl } = image
      _assets = { ..._assets, image: { width, height, imageUrl,  } }
    }

    return {
      id: native.id,
      attributes: native.attributes,
      categories: native.categories,
      bidEcpm: Number(native.bidEcpm),
      name: native.name,
      dealsOptions: native.dealsOptions,
      deals: native.deals,
      sourceId: native.sourceId,
      ruleGroups: ruleGroups
        ? {
            operator: ruleGroups?.operator,
            values: ruleGroups?.values.map((v:any) => v.filter((i: null | undefined) => i !== null && i !== undefined)),
          }
        : undefined,
      tags: native.tags,
      accountId: native.accountId,
      intervalStart: interval?.[0] as Date,
      intervalEnd: interval?.[1] as Date,
      contentType: contentType,
      assets: _assets,
      associatedProductsList: native.associatedProducts
    }
  }
}
